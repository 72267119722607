exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mb-terms-tsx": () => import("./../../../src/pages/MbTerms.tsx" /* webpackChunkName: "component---src-pages-mb-terms-tsx" */),
  "component---src-pages-outages-tsx": () => import("./../../../src/pages/outages.tsx" /* webpackChunkName: "component---src-pages-outages-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-platformprivacy-tsx": () => import("./../../../src/pages/platformprivacy.tsx" /* webpackChunkName: "component---src-pages-platformprivacy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */),
  "component---src-pages-responsibledisclosure-tsx": () => import("./../../../src/pages/responsibledisclosure.tsx" /* webpackChunkName: "component---src-pages-responsibledisclosure-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-websiteprivacy-tsx": () => import("./../../../src/pages/websiteprivacy.tsx" /* webpackChunkName: "component---src-pages-websiteprivacy-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-customerstory-template-tsx": () => import("./../../../src/templates/customerstoryTemplate.tsx" /* webpackChunkName: "component---src-templates-customerstory-template-tsx" */)
}

